import React from 'react'
import { useSelector } from 'react-redux'

export const RowAsignatura = ({asignatura}) => {
    const {calificacionesDetalleAsignatura } = useSelector(state => state.libroDigital);
    const {estudianteActivo}= useSelector(state => state.estudiante)
    const calificacionesAsignaturaActiva = calificacionesDetalleAsignatura.filter(e => e.asignatura == asignatura._id);
    const calificacionesEstudiante = calificacionesAsignaturaActiva.filter(e => e.estudiante == estudianteActivo);
    console.log(calificacionesEstudiante);
    console.log(calificacionesEstudiante.length);
    let promedioPrimerSemestre=0;
    calificacionesEstudiante.forEach(element => {
        promedioPrimerSemestre= element.calificacion+ promedioPrimerSemestre
    });
    const promedio = promedioPrimerSemestre/calificacionesEstudiante.length
    return (
        <tr>
            <td>{asignatura.nombre}</td>
            <td>{promedio.toFixed(1)}</td>
        </tr>
    )
}
