import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RowAsignatura } from './RowAsignatura'
import { libroDigitalTablaEstudiantes } from '../../../../../action/router'

export const CertificadoFinalScreen = () => {
    const dispatch = useDispatch()
    const {asignaturas, cursoActivo, cursos} = useSelector(state=>state.libroDigital)
    const { estudianteActivo, estudiantes } = useSelector(state => state.estudiante);
    const { docentes, credencialesUsuario } = useSelector(state => state.usuario);
    const cursoEstudiante = cursos.find(e=> e._id === cursoActivo)
    const docenteCurso = docentes.find(e=> e._id === cursoEstudiante.docente);
    
    const asignaturaCurso = asignaturas.filter(e => e.curso === cursoActivo)
    const estudiante = estudiantes.find(e=> e._id === estudianteActivo);
    console.log(cursoActivo)
    console.log(asignaturaCurso)
    const decretos = [
        { establecimiento:"640be43138221b36044b87ec", texto:'Decretos de Evaluación y Promoción Escolar N°2169 año 2007 - Decretos Planes y Programas de Estudio N°1000 año 2009. Reconocido oficialmente por el Ministerio de Educación de la República de Chile según Resolución N° 0005/ 06 de enero 2015, Rol Base de Datos N°20.326-2 de la Cuarta Región de Coquimbo, Provincia de Elqui, Comuna de La Serena.'},
        { establecimiento:"640be43b38221b36044b87ef", texto:'Decretos de Evaluación y Promoción Escolar N°2169 año 2007 - Decretos Planes y Programas de Estudio N°1000 año 2009. Reconocido oficialmente por el Ministerio de Educación de la República de Chile según Resolución N° 1500/ 28 de enero 2022, Rol Base de Datos N°42079-4 de la Cuarta Región de Coquimbo, Provincia de Elqui, Comuna de COQUIMBO.'}
    ];
    // const decretoActual= decretos.find(e=> e.establecimiento === credencialEstablecimiento.establecimiento)
    
    const handleRegresar = (e) => {
        e.preventDefault();
        dispatch(libroDigitalTablaEstudiantes())
    }
  return (
    <div className='row'>
        <div className="col-12">
            <h4>INFORME DE CALIFICACIONES AÑO ACADÉMICO 2024</h4>
            {/* <h6>{decretoActual.texto}</h6> */}
            <h5>Estudiante: <strong>{estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno}</strong></h5>
            <h5>RUT: <strong>{estudiante.rut}</strong></h5>
            <h5>Curso: <strong>{cursoEstudiante.nombre}</strong></h5>
            <h5>Docente: <strong>{docenteCurso.nombres} {docenteCurso.apellidoPaterno} {docenteCurso.apellidoMaterno}</strong></h5>
        </div> 
        <div className='col-6'>
            <h4>Segundo Semestre</h4>
            <table className='table table-bordered table-hover'>
                <thead>
                    <tr>
                        <th>Asignatura</th>
                        <th>Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {asignaturaCurso && asignaturaCurso.map((e,index) => (
                        <RowAsignatura
                            key={e._id}
                            asignatura={e}
                            index={index + 1}
                        />

                    ))}
                </tbody>
                <tfoot>
                    <tr>
                    <td>
                        <div className='d-grid gap-2'>
                        <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                        </div>
                    </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        {/* <div className='col-4'>
            <h4>Segundo Semestre</h4>
            <table className='table table-bordered table-hover'>
                <thead>
                    <tr>
                        <th>Asignatura</th>
                        <th>Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {asignaturas && asignaturas.map((e,index) => (
                        <RowAsignatura
                            key={e._id}
                            asignatura={e}
                            index={index + 1}
                        />

                    ))}
                </tbody>
            </table>
        </div> */}
        {/* <div className='col-4'>
            <h4>Final</h4>
            <table className='table table-bordered table-hover'>
                <thead>
                    <tr>
                        <th>Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {asignaturas && asignaturas.map((e,index) => (
                        <tr><td><input className="input is-small" type="text" placeholder="" /></td></tr>

                    ))}
                </tbody>
            </table>
        </div> */}
    </div>
  )
}
