import React from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { activarClaseTaller } from '../../../../action/clasesTaller';
import {  libroDigitalFormClaseTallerActualizar } from '../../../../action/router';

export const RowClaseTaller = ({claseTaller, docentes, index}) => {
  const dispatch=useDispatch()
  console.log(docentes)
  let docentePrincipal = docentes.find(e=>e._id == claseTaller.docente);
  console.log(docentePrincipal)
  if (!docentePrincipal){
    docentePrincipal = {
      nombres:"",
      apellidoMaterno:"",
      apellidoPaterno:""
    }
  }
  const handleContenido = () => {
    dispatch(activarClaseTaller(claseTaller._id));
    Swal.fire({
      title: claseTaller.titulo,
      text: claseTaller.objetivo,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }
  
  const handleActualizar = () => {
    dispatch(activarClaseTaller(claseTaller._id));
    dispatch(libroDigitalFormClaseTallerActualizar());
  }

  return (
    <tr>
      <th>{index}</th>
      <td>{claseTaller.titulo}</td>
      <td>{docentePrincipal.nombres} {docentePrincipal.apellidoPaterno} {docentePrincipal.apellidoMaterno}</td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-info btn-sm' onClick={handleContenido} >Contenido</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-warning btn-sm' onClick={handleActualizar} >Actualizar</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-danger btn-sm' disabled >Eliminar</button>
        </div>
      </td>
    </tr>
  )
}
