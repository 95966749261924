import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { activarAsistencia } from '../../../../action/asistencia';
import { libroDigitalTablaAsistencias, libroDigitalTablaCursos } from '../../../../action/router';
import { RowEstudiante } from './RowEstudiante';
import { activarCurso } from '../../../../action/curso';
import { useEffect } from 'react';
import { obtenerCalificacionesAsignatura } from '../../../../action/calificacionAsignatura';
import { obtenerCalificacionesTaller } from '../../../../action/calificacionTaller';
import { obtenerCalificacionesDetalleAsignatura } from '../../../../action/calificacionDetalleAsignatura';
import { obtenerCalificacionDetallesTaller } from '../../../../action/calificacionDetalleTaller';

export const TablaEstudiantesCurso = ({estudiantes, asignaturas, periodosEscolares}) => {
  const dispatch=useDispatch();
  const {cursoActivo} = useSelector(state => state.libroDigital)
  const handleRegresar = () => {
    dispatch(activarCurso(''))
    dispatch(libroDigitalTablaCursos())
  }

  useEffect(() => {
    if(cursoActivo !=='' && cursoActivo !== null ){
      const filtro = {
        curso: cursoActivo
      }
      const obtenerCalificacionesAsignaturasCurso = () => dispatch(obtenerCalificacionesAsignatura(filtro));
      const obtenerCalificacionDetallesAsignaturasCursoPrincipal = () => dispatch(obtenerCalificacionesDetalleAsignatura(filtro));
      const obtenerCalificacionesTalleresCurso = () => dispatch(obtenerCalificacionesTaller(filtro));
      const obtenerCalificacionDetallesTalleresCursoPrincipal = () => dispatch(obtenerCalificacionDetallesTaller(filtro));
      obtenerCalificacionesAsignaturasCurso()
      obtenerCalificacionDetallesAsignaturasCursoPrincipal()
      obtenerCalificacionesTalleresCurso()
      obtenerCalificacionDetallesTalleresCursoPrincipal()
    }
    // eslint-disable-next-line
  }, [cursoActivo]); 

  return (
    <table className='table table-bordered table-hover'>
      <thead>
          <tr>
              <th>N°</th>
              <th>Estudiante</th>
              <th>Anotaciones</th>
              <th>Primer Semestre</th>
              <th>Segundo Semestre</th>
              <th>Final</th>
          </tr>
      </thead>
      <tbody>
          {estudiantes && estudiantes.map((e,index) => (
              <RowEstudiante
                  key={e._id}
                  estudiante={e}
                  periodosEscolares={periodosEscolares}
                  index={index + 1}
              />

          ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className='d-grid gap-2'>
              <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
