import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { libroDigitalAsistenciaInformeScreen, libroDigitalAsistenciaRegistroScreen, libroDigitalAsistenciaScreen, libroDigitalTablaCursos } from '../../../action/router';
import { AsistenciaRegistroScreen } from './AsistenciaRegistroScreen';
import { AsistenciaInformeScreen } from './AsistenciaInformeScreen';

export const AsistenciaScreen = () => {
    const dispatch = useDispatch();
    const { libroDigitalAsistenciaRegistroScreen: registroScreen, libroDigitalAsistenciaInformeScreen: informeScreen } = useSelector(state => state.router)

    const handleRegistro = () => {
        dispatch(libroDigitalAsistenciaRegistroScreen());
    } 

    const handleInforme = () => {
        dispatch(libroDigitalAsistenciaInformeScreen());
    }     

    const handleRegresar = () => {
        dispatch(libroDigitalTablaCursos());

    }
    return (
        <div>
            <div className="row">
                <div className='col-6'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary btn-sm' onClick={handleRegistro} >Registro</button>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-primary btn-sm' onClick={handleInforme} >Informe</button>
                    </div>
                </div>
                { registroScreen &&
                    <div className='col-12'>
                        <AsistenciaRegistroScreen />
                    </div>
                }
                { informeScreen &&
                    <div className='col-12'>
                        <AsistenciaInformeScreen />
                    </div>
                }
            </div>
            <div className='row'>    
                <div className='col-4'>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}